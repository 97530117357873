import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import AuthLayout from '../../container/profile/authentication/Index';

const Onboarding = lazy(() => import('../../container/onboarding/Index'));
const Quotes = lazy(() => import('../../container/onboarding/quotes'));
const QuotesDetails = lazy(() => import('../../container/onboarding/quotes/details'));
const NotFound = lazy(() => import('../../container/pages/404'));

const OnboardingRoutes = React.memo(() => {
  return (
    <Routes>
      <Route path="/quotes" element={<Quotes />} />
      <Route path="/quotes/details/:quoteId" element={<QuotesDetails />} />
      <Route path="/" element={<Onboarding />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
});

export default AuthLayout(OnboardingRoutes);
