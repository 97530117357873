import { ConfigProvider, Spin } from 'antd';
import 'antd/dist/antd.less';
import React, { lazy, useEffect, useState } from 'react';
import { Provider, useSelector } from 'react-redux';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';

import { ThemeProvider } from 'styled-components';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
// import ProtectedRoute from './components/utilities/protectedRoute';
import config from './config/config';
import store from './redux/store';

import Admin from './routes/admin';
import Onboarding from './routes/onboarding';
import Booking from './routes/booking';
import Auth from './routes/auth';
import './static/css/style.css';

const NotFound = lazy(() => import('./container/pages/404'));

const { themeColor } = config;

function ProviderConfig() {
  const { rtl, topMenu, mainContent, onboardingDone } = useSelector((state) => {
    return {
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
      mainContent: state.ChangeLayoutMode.mode,
      onboardingDone: state.onboarding.onboardingDone,
    };
  });

  const { isAuthenticated, isLoading } = useAuth0();

  const [path, setPath] = useState(window.location.pathname);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setPath(window.location.pathname);
    }
    return () => {
      unmounted = true;
    };
  }, [setPath]);

  if (isLoading) {
    return (
      <div className="spin">
        <Spin />
      </div>
    );
  }

  return (
    <ConfigProvider direction={rtl ? 'rtl' : 'ltr'}>
      <ThemeProvider theme={{ ...themeColor, rtl, topMenu, mainContent }}>
        <>
          <Router basename={process.env.PUBLIC_URL}>
            {!isAuthenticated ? (
              <Routes>
                <Route path="/onboarding/*" element={<Onboarding />} />
                <Route path="*" element={<Auth />} />
              </Routes>
            ) : (
              <Routes>
                <Route path="/onboarding/*" element={<Onboarding />} />
                <Route path="/booking/*" element={<Booking />} />
                <Route path="/admin/*" element={<Admin />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            )}
            {isAuthenticated && (path === process.env.PUBLIC_URL || path === `${process.env.PUBLIC_URL}/`) && (
              <Routes>
                <Route path="/" element={<Navigate to={onboardingDone ? '/booking' : '/onboarding'} />} />
              </Routes>
            )}
          </Router>
        </>
      </ThemeProvider>
    </ConfigProvider>
  );
}

function App() {
  return (
    <Provider store={store}>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        authorizationParams={{
          redirect_uri: window.location.origin,
          display: 'popup',
        }}
        cacheLocation="localstorage"
      >
        <ProviderConfig />
      </Auth0Provider>
    </Provider>
  );
}

export default App;
