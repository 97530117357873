const actions = {
  ONBOARDING_DONE: 'ONBOARDING_DONE',

  onboardingDone: () => {
    return {
      type: actions.ONBOARDING_DONE,
    };
  },
};

export default actions;
