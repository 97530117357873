import { Spin } from 'antd';
import React, { Suspense } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { AuthenticationWrap } from './overview/style';
import AuthInfo from '../../../components/utilities/auth-info/info';

const AuthLayout = (WrapperContent) => {
  return function () {
    const { isAuthenticated } = useAuth0();
    return (
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <AuthenticationWrap style={{ backgroundImage: `url("${require('../../../static/img/admin-bg-light.png')}")` }}>
          <div className="ninjadash-authentication-wrap">
            <div className="ninjadash-authentication-brand">
              <img src="/logo_dark.svg" alt="Zazu" width={120} height={120} />
              {isAuthenticated && (
                <div style={{ position: 'absolute', right: '10px', top: '40px' }}>
                  <AuthInfo />
                </div>
              )}
            </div>
            <WrapperContent />
          </div>
        </AuthenticationWrap>
      </Suspense>
    );
  };
};

export default AuthLayout;
