import UilAngleDown from '@iconscout/react-unicons/icons/uil-angle-down';
// import UilBell from '@iconscout/react-unicons/icons/uil-bell';
// import UilDollarSign from '@iconscout/react-unicons/icons/uil-dollar-sign';
// import UilSetting from '@iconscout/react-unicons/icons/uil-setting';
// import UilUser from '@iconscout/react-unicons/icons/uil-user';
// import UilUsersAlt from '@iconscout/react-unicons/icons/uil-users-alt';
import UilSignout from '@iconscout/react-unicons/icons/uil-signout';
import { Avatar } from 'antd';
import React from 'react'; //  useState
// import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';

import { InfoWrapper, UserDropDown } from './auth-info-style';
// import Message from './Message';
// import Notification from './Notification';
// import Search from './Search';
// import Settings from './settings';
import { logOut } from '../../../redux/authentication/actionCreator';

// import { Dropdown } from '../../dropdown/dropdown';
// import Heading from '../../heading/heading';
import { Popover } from '../../popup/popup';

const AuthInfo = React.memo(() => {
  const dispatch = useDispatch();

  const { logout, user } = useAuth0();
  const { name } = user;
  // const [state, setState] = useState({
  //   flag: 'en',
  // });
  // const navigate = useNavigate();
  // const { i18n } = useTranslation();
  // const { flag } = state;

  const SignOut = (e) => {
    e.preventDefault();

    dispatch(
      logOut(() => {
        logout({
          logoutParams: {
            returnTo: window.location.origin,
          },
        });
      }),
    );
  };

  const userContent = (
    <UserDropDown>
      <div className="user-dropdown">
        {/* <figure className="user-dropdown__info">
          <img src={require('../../../static/img/avatar/chat-auth.png')} alt="" />
          <figcaption>
            <Heading as="h5">Abdullah Bin Talha</Heading>
            <p>UI Expert</p>
          </figcaption>
        </figure> */}
        {/* <ul className="user-dropdown__links">
          <li>
            <Link to="#">
              <UilUser /> Profile
            </Link>
          </li>
          <li>
            <Link to="#">
              <UilSetting /> Settings
            </Link>
          </li>
          <li>
            <Link to="#">
              <UilDollarSign /> Billing
            </Link>
          </li>
          <li>
            <Link to="#">
              <UilUsersAlt /> Activity
            </Link>
          </li>
          <li>
            <Link to="#">
              <UilBell /> Help
            </Link>
          </li>
        </ul> */}
        <Link className="user-dropdown__bottomAction" onClick={SignOut} to="#">
          <UilSignout /> Sign Out
        </Link>
      </div>
    </UserDropDown>
  );

  // const onFlagChangeHandle = (value, e) => {
  //   e.preventDefault();
  //   setState({
  //     ...state,
  //     flag: value,
  //   });
  //   i18n.changeLanguage(value);
  // };

  // const country = (
  //   <NavAuth>
  //     <Link onClick={(e) => onFlagChangeHandle('en', e)} to="#">
  //       <img src={require('../../../static/img/flag/en.png')} alt="" />
  //       <span>English</span>
  //     </Link>
  //     <Link onClick={(e) => onFlagChangeHandle('esp', e)} to="#">
  //       <img src={require('../../../static/img/flag/esp.png')} alt="" />
  //       <span>Spanish</span>
  //     </Link>
  //     <Link onClick={(e) => onFlagChangeHandle('ar', e)} to="#">
  //       <img src={require('../../../static/img/flag/ar.png')} alt="" />
  //       <span>Arabic</span>
  //     </Link>
  //   </NavAuth>
  // );

  return (
    <InfoWrapper>
      {/* <Search />
      <Message />
      <Notification />
      <Settings />
      <div className="ninjadash-nav-actions__item ninjadash-nav-actions__language">
        <Dropdown placement="bottomRight" content={country} trigger="click">
          <Link to="#" className="ninjadash-nav-action-link">
            <img src={require(`../../../static/img/flag/${flag}.png`)} alt="" />
          </Link>
        </Dropdown>
      </div> */}
      <div className="ninjadash-nav-actions__item ninjadash-nav-actions__author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="ninjadash-nav-action-link">
            <Avatar src="https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png" />
            <span className="ninjadash-nav-actions__author--name" style={{ paddingLeft: '5px' }}>
              {name ?? 'Profile'}
            </span>
            <UilAngleDown />
          </Link>
        </Popover>
      </div>
    </InfoWrapper>
  );
});

export default AuthInfo;
