import actions from './actions';

const { ONBOARDING_DONE } = actions;
const onboardingLocalStorageKey = 'onboardingDone';

const initState = {
  onboardingDone: (localStorage.getItem(onboardingLocalStorageKey) ?? 'false') === 'true',
};

const OnboardingReducer = (state = initState, action) => {
  const { type } = action;
  switch (type) {
    case ONBOARDING_DONE:
      return {
        ...state,
        onboardingDone: true,
      };
    default:
      return state;
  }
};
export { OnboardingReducer, onboardingLocalStorageKey };
