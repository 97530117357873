import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import AuthLayout from '../../container/profile/authentication/Index';

const Booking = lazy(() => import('../../container/onboarding/booking'));
const SignAgreement = lazy(() => import('../../container/onboarding/sign-agreement'));
const ThankYou = lazy(() => import('../../container/onboarding/thank-you'));
const NotFound = lazy(() => import('../../container/pages/404'));

const BookingRoutes = React.memo(() => {
  return (
    <Routes>
      <Route path="/" element={<Booking />} />
      <Route path="/sign-agreement" element={<SignAgreement />} />
      <Route path="/thank-you" element={<ThankYou />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
});

export default AuthLayout(BookingRoutes);
